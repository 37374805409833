$color-black: #2D2D2D;
$color-white: #FFFFFF;
$color-dark: #9C8B58;
$color-dark-blue: #2C3E57;
$color-grey: #949494;
$color-light-grey: #DDDDDD;
$color-yellow: #FDF2C5;
$color-white-grey: #F8F8FC;
$color-green: #00AC3C;
$color-blue: #136DC7;
$color-red: #D14343;
$color-chat-grey: #F0F0F0;
$theme-scroll-color: #005b41;
$theme-color-input: #19212F;
$theme-btn-color: #005B41;
$theme-color-Faqs: #19212F;
$side-nav-color: #1A212F;

$theme-background-color: #FAFAFA;
$top-nav-color: #111322;
$text-color:#F75DBE;
// $top-nav-color: #867CF3;